<template>
  <v-content v-if="!success">
    <v-container fill-height>
      <v-layout align-center justify-center class="signin_layout">
        <div class="signin_box_wrapper">          
          <div class="signin_box_img"></div>
          <div class="signin_box">
            <div class="signin_header">
              <img src="@/assets/img/bici/demo.png" width="40"/>
              <div class="signin_title">
                <small>ATAGlance | DEMO</small>
                <span>Sign In to user account</span>
              </div>
            </div>
            <!-- showLocalLogin이 true이거나 forlocal이 true일 때 보여줄 로그인 폼 -->
            <div v-if="showLocalLogin || forlocal" class="login-form">
              <div class="input_fields">
                <v-text-field 
                  v-model="username" 
                  name="signin" 
                  label="Email" 
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  outlined
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  @keyup.enter="handleSubmit()"
                ></v-text-field>
                <v-text-field
                  v-model="otpCode"   
                  name="otpCode"
                  label="Otp Code"
                  type="text"
                  :rules="otpRules"
                  maxlength="6"
                  @keyup.enter="handleSubmit()"
                ></v-text-field>
              </div>
              
              <div class="forgot"><router-link to="/Forgot">Forgot Password?</router-link></div>
              <div v-bind:style="incorrect" class="incorrect">{{errMsg}}</div>
              <div class="button_wrapper">
                <v-btn color="warning" @click="handleSubmit()">Sign-In</v-btn>
                <v-btn v-if="!forlocal" color="warning" @click="backSubmit()">Back</v-btn>
                <!--v-btn color="warning" @click="msSubmit()">MS Sign-In</v-btn -->
                <!--v-btn color="warning" @click="kcSubmit()">JGS Sign-In</v-btn -->
                <!-- <v-btn color="warning" @click="ssoSubmit()">JGS SSO</v-btn> -->
              </div>
              
              <div class="register">
                <span @click="downloadPDF" title="How to Account Register Guidance"><img src="@/assets/img/icon/help.svg"/></span>
                Click here to <router-link to="/register">Get Permission</router-link>
              </div>
              <div class="description">
                Optimized for viewing in 
                <a href="https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAjwiMj2BRBFEiwAYfTbClJ4Iht0So5ri1TexRLdt6qK6Z_26bkFyF3nyzLsIvC_RYWydvVAuBoCgp8QAvD_BwE&gclsrc=aw.ds" target="_blank">
                Chrome
                </a>
                <br>to provide better reporting service.
              </div>
            </div>
            <!-- forlocal이 false이고 showLocalLogin이 false일 때 보여줄 버튼들 -->
            <div v-else class="button-container">
              <v-btn color="warning" class="sso-btn" @click="ssoSubmit()">WOODSIDE MEMBER SIGN-IN</v-btn>
              <v-btn color="warning" class="sso-btn" @click="showJgsLogin()">JGS MEMBER SIGN-IN</v-btn>
              <div class="description2">
                Optimized for viewing in 
                <a href="https://www.google.com/chrome/?brand=CHBD&gclid=CjwKCAjwiMj2BRBFEiwAYfTbClJ4Iht0So5ri1TexRLdt6qK6Z_26bkFyF3nyzLsIvC_RYWydvVAuBoCgp8QAvD_BwE&gclsrc=aw.ds" target="_blank">
                Chrome
                </a>
                <br>to provide better reporting service.
              </div>
            </div>
          </div>
          
        </div>
      </v-layout>
      <!-- OTP 등록 다이얼로그 -->
      <v-dialog v-model="otpRegVisible" persistent max-width="600px">
        <otp-reg 
          :userName="username"
          @otpRegisterSuccess="handleOtpRegisterSuccess"
          @otpRegisterNewCancel="handleOtpRegisterNewCancel"
          @otpRegisterCancel="handleOtpRegisterCancel">
        </otp-reg>
      </v-dialog>
      <!-- 비밀번호 재설정 다이얼로그 -->
      <v-dialog v-model="passwordResetVisible" persistent max-width="600px">
        <password-reset 
          :visible="passwordResetVisible"
          :account="account"
          @passwordResetSuccess="handlePasswordResetSuccess"
          @passwordResetCancel="handlePasswordResetCancel"
          @passwordResetForgot="handlePasswordResetForgot">
        </password-reset>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import '@/assets/stylus/ui/component/_signin.styl'

import { mapState, mapActions } from 'vuex'
import __C from '../primitives/_constant_'
import ServiceRoutes from '@/mixins/service.routers.mixin'
import { msalInstance } from '@/mixins/authConfig'
import PasswordEncMixin from '@/mixins/password.enc'
import OtpReg from './OtpReg.vue'
import PasswordReset from './PasswordReset.vue'
// import * as Keycloak from 'keycloak-js'
// import { initKeycloak, handleKeycloakAuth } from '@/keycloak' // Keycloak 설정 파일 임포트

export default {
  name: 'signin',
  mixins: [
    ServiceRoutes,PasswordEncMixin
  ],
  components: {
    OtpReg,
    PasswordReset
  },
  data: () => ({
    otpCode: __C.CONFIG.FOR_LOCAL?'999999':'', // 사용자 입력 OTP 코드
    otpRegVisible: false, // 다이얼로그 표시 여부
    passwordResetVisible: false, // 비밀번호 재설정 팝업 표시 여부
    errMsg: '',
    accountService: null,
    username: '',
    password: '',
    submitted: false,
    success: false,
    incorrect: {
      visibility: 'hidden'
    },
    otpRules: [
      v => !!v || 'OTP is required', // 필수 입력 검사
      v => /^\d{6}$/.test(v) || 'OTP must be exactly 6 digits', // 6자리 숫자 검사
    ],
    isAuthenticated: false,
    loginRequest:{ 
      scopes: [],
    },
  }),
  created() {
    this.success = false
  },
  watch: {
    username(v) {
      if (v == '') {
        this.incorrect.visibility='hidden'
      }
    },
    password(v) {
      if (v == '') {
        this.incorrect.visibility='hidden'
      }
    },
    otpCode(v) {
      if (v == '') {
        this.incorrect.visibility='hidden'
      }
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['status','account','showLocalLogin']),
    forlocal : function(){
      return __C.CONFIG.FOR_LOCAL
    }
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['signin', 'signms', 'signout','setShowLocalLogin']),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, ['initialize']),

    
    // // Keycloak LOGIN click event
    // async handleKeycloakLogin() {
    //   try {
    //     alert(`signin methods initKeycloak call `)
    //     await initKeycloak()
    //   } catch (error) {
    //     alert(`signin methods initKeycloak error-${error}`)
    //     console.error('Keycloak initialization failed:', error)
    //     this.incorrect.visibility = 'visible'
    //     this.errMsg = 'Failed to initialize login. Please try again.'
    //   }
    // },

    
    // // Keycloak callback event
    // async handleKeycloakCallback() {
    //   try {
    //     alert(`signin methods handleKeycloakAuth call `)
    //     const result = await handleKeycloakAuth()
        
    //     if (result.success) {
    //       alert(`signin methods handleKeycloakAuth return success ${result}`)
    //       const success = await this.signkc(result.data)
          
    //       if (!success.error) {
    //         const navItems = await this.initialize()
    //         this.setServiceRouter(navItems)
    //         this.$router.replace('/')
    //         this.incorrect.visibility = 'hidden'
    //       } else {
    //         this.incorrect.visibility = 'visible'
    //         this.errMsg = success.message
    //       }
    //     } else {
    //       alert(`signin methods handleKeycloakAuth return error ${result}`)
    //       this.incorrect.visibility = 'visible'
    //       this.errMsg = result.error || 'Authentication failed'
    //     }
    //   } catch (error) {
    //     alert(`signin methods handleKeycloakAuth call error ${error}`)
    //     console.error('Keycloak authentication error:', error)
    //     this.incorrect.visibility = 'visible'
    //     this.errMsg = 'Authentication failed. Please try again.'
    //   }
    // },

    // JGS MEMBER SIGN-IN click event
    showJgsLogin() {
      this.setShowLocalLogin(true)
    },
    // WOODSIDE MEMBER SIGN-IN click event
    ssoSubmit() {
      this.setShowLocalLogin(false)
      window.location.href = `${__C.LANDING_PAGE_NAME}`
    },
    
    // MSAL SIGN-IN click event
    async msSubmit() {

      const msResponse = await msalInstance.loginPopup(this.loginRequest)
      // console.log(msResponse)
      this.isAuthenticated = true
      const loginRequest = {
        accessToken : msResponse.idToken,
        tenantId : msResponse.tenantId,
      }
      //alert('loginPopup login success')
      this.signms(loginRequest).then(success => {
        if(!success.error) {
          this.initialize().then(navItems => {
            this.setServiceRouter(navItems)
            this.$router.replace('/', () => {})
            this.incorrect.visibility='hidden'
          })
          
        } else{
          this.incorrect.visibility='visible'
          this.errMsg = success.message
        }
      })
    },
    // BACK click event
    backSubmit() {
      //this.setShowLocalLogin(false)
      localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
      localStorage.removeItem('showLocalLogin')

      window.location.href = __C.HOST_NAME
    },
    // GENERAL SIGN-IN click event
    async handleSubmit() {
      
      // console.log('signin created',__C.CONFIG.FOR_LOCAL)
      this.submitted = true
      const { username, password,otpCode } = this
      
      // 비밀번호를 SHA-256으로 해싱
      let password_enc = await this.setPasswordEnc(password)
      
      this.signin({ username, password:password_enc, otpCode }).then(success => {
        if(!success.error) {
          
          const {otpUseYN, otpRegYN,tmpPasswdYN='N' } = success
          // OTP 사용 여부와 등록 여부 확인

          if (otpUseYN === 'Y' && otpRegYN === 'N') {
            // OTP 등록이 필요할 때 OTP 등록 창을 띄움
            this.otpRegVisible = true
          } else if (tmpPasswdYN === 'Y') {
            // 비밀번호 변경이 필요한 경우 팝업 표시
            this.passwordResetVisible = true
          } else {
            // OTP 등록이 완료된 경우 로그인 성공 처리
            this.initialize().then(navItems => {
              this.setServiceRouter(navItems)
              this.$router.replace('/', () => {})
              this.incorrect.visibility = 'hidden'  // 오류 메시지 숨기기
            })
          }
        } else{
          this.incorrect.visibility='visible'
          this.errMsg = success.message
        }
      })
    },

    // Password Reset Success dialog event
    handlePasswordResetSuccess() {
      // 비밀번호 변경 성공 후 처리
      //this.resetTmpPasswdFlag({ newPassword }).then(() => {
      if (this.$route.path !== '/signin') {
        this.$router.replace('/signin').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        });
      }      
      localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
      this.incorrect.visibility = 'hidden'  // 오류 메시지 숨기기
      this.passwordResetVisible = false
      //})
    },

    // Password Reset Cancel dialog event
    handlePasswordResetCancel() {
      // 비밀번호 변경없이 취소
      //this.resetTmpPasswdFlag({ newPassword }).then(() => {
      if (this.$route.path !== '/signin') {
        this.$router.replace('/signin').catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
      }
      localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
      this.incorrect.visibility = 'hidden'  // 오류 메시지 숨기기
      this.passwordResetVisible = false
      //})
    },

    // Password Reset Forgot dialog event
    handlePasswordResetForgot() {
      // resend code
      //this.resetTmpPasswdFlag({ newPassword }).then(() => {
      localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
      this.incorrect.visibility = 'hidden'  // 오류 메시지 숨기기
      this.passwordResetVisible = false
      // Forgot Password 페이지로 이동
      this.$router.push('/Forgot')
    },
    downloadPDF(){
      let link = document.createElement('a')
      link.href = `${__C.HOST_NAME}/resources/pdf/JGS_Account_Register_Guidance_ver1.0.pdf`
      // link.href = `${__C.HOST_NAME}/img/JGS_Account_Register_Guidance_ver1.0.pdf`
      document.body.appendChild(link)
      link.click()
    },

    // OTP Register Success dialog event
    handleOtpRegisterSuccess() {
      // OTP 등록 성공 후 처리
      this.initialize().then(navItems => {
        this.setServiceRouter(navItems)
        this.$router.replace('/', () => {})
        this.incorrect.visibility = 'hidden'  // 오류 메시지 숨기기
        this.otpRegVisible = false // 다이얼로그 닫기
      })
    },
    // OTP Register New Cancel dialog event
    handleOtpRegisterNewCancel() {
      // OTP 등록전 취소 처리
      this.otpRegVisible = false
    },
    // OTP Register Cancel dialog event
    handleOtpRegisterCancel() {
      // OTP 등록후 취소 처리
      this.otpRegVisible = false
    },


  }
}
</script>
